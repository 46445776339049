<template>
    <div id="icon" class="aspect-square"
        :style="'-webkit-mask-image: url(' + iconImg + '); -webkit-mask-repeat: no-repeat; -webkit-mask-position: center; -webkit-mask-size: contain;'">
    </div>
</template>

<script lang="ts">
//this component
import { defineComponent } from 'vue';

export default defineComponent({
    name: "IconComponent",
    props: {
        iconImg: String
    }
});
</script>