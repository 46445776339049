<template>
  <NavBar :options="menuOptions" :socialNetworks="socialNetworks"></NavBar>
  <router-view class="mt-16" :socialNetworks="socialNetworks" />

  <!-- Footer -->
  <footer class="bg-zinc-950 py-8 text-white">
    <div class="container flex flex-col gap-8 md:flex-row md:justify-between">
      <!-- Quick menu -->
      <div id="quickMenu" class="flex flex-col items-center md:block space-y-2">
        <h5 class="text-xs">Menu</h5>
        <div id="options" class="flex flex-col md:block items-center space-y-1 opacity-50">
          <RouterLink :to="option.link" v-for="option, index in menuOptions" :key="index" class="block">
            <p>{{ option.name }}</p>
          </RouterLink>
        </div>
      </div>

      <!-- Contact info -->
      <div id="contactInfo" class="space-y-2 flex flex-col items-center md:block">
        <h5 class="text-xs">Información de contacto</h5>
        <div id="contacts" class="space-y-1 flex flex-col items-center md:block opacity-50">
          <div id="contact" class="flex gap-2" v-for="contact, index in contactInfo" :key="index">
            <Icon :iconImg="contact.icon" class="w-4 bg-white"></Icon>
            <p>{{ contact.info }}</p>
          </div>
        </div>
      </div>

      <!-- Social networks -->
      <div id="socialNetworks" class="space-y-2 flex flex-col items-center md:block">
        <h5 class="text-xs">Redes sociales</h5>
        <div id="socialNetworksList" class="flex gap-2 items-center">
          <a :href="socialNetwork.link" v-for="socialNetwork, index in socialNetworks" :key="index">
            <div
              class="icon p-1.5 rounded-lg overflow-hidden border border-white border-opacity-50 opacity-70 hover:opacity-100"
              :class="socialNetwork.color">
              <Icon :iconImg="socialNetwork.icon" class="w-6 bg-white"></Icon>
            </div>
          </a>
        </div>
      </div>

      <!-- Developer -->
      <div class="developer flex gap-8 text-center text-white">
        <div class="flex flex-col gap-2 items-center mx-auto">
          <span class="text-xs">Desarrollado por</span>
          <a href="https://adro.studio" class="text-xl opacity-50">adro.studio</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
// Customs components
import NavBar from './components/NavBar.vue';
import Icon from './components/Icon.vue';

// This view
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
    Icon
  },
  data() {
    return {
      menuOptions: [
        {
          name: 'Inicio',
          link: '/#inicio'
        },
        {
          name: 'Sobre nosotros',
          link: '/#sobre-nosotros'
        },
        {
          name: 'Servicios',
          link: '/#servicios'
        },
        {
          name: 'Contacto',
          link: '/#contactanos'
        }
      ],
      socialNetworks: [
        {
          icon: '/assets/icons/socialNetworks/whatsapp.svg',
          link: 'whatsapp',
          color: 'bg-green-500'
        },
        {
          icon: '/assets/icons/socialNetworks/linkedin.svg',
          link: 'linkedin',
          color: 'bg-blue-700'
        },
        {
          icon: '/assets/icons/socialNetworks/instagram.svg',
          link: 'instagram',
          color: 'bg-gradient-to-bl from-[#833AB4] via-[#FD1D1D] to-[#FCB045]'
        },
        {
          icon: '/assets/icons/socialNetworks/x.svg',
          link: 'x',
          color: 'bg-black'
        }
      ],
      contactInfo: [
        {
          icon: '/assets/icons/location.svg',
          info: 'Calle falsa 123'
        },
        {
          icon: '/assets/icons/phone.svg',
          info: '(+54) 9 11 1234-5678'
        },
        {
          icon: '/assets/icons/mail.svg',
          info: 'ejemplo@gmail.com'
        }
      ]
    }
  }
})
</script>

<style>
#app {
  font-family: "Poppins", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
