import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "bg-zinc-950 py-8 text-white" }
const _hoisted_2 = { class: "container flex flex-col gap-8 md:flex-row md:justify-between" }
const _hoisted_3 = {
  id: "quickMenu",
  class: "flex flex-col items-center md:block space-y-2"
}
const _hoisted_4 = {
  id: "options",
  class: "flex flex-col md:block items-center space-y-1 opacity-50"
}
const _hoisted_5 = {
  id: "contactInfo",
  class: "space-y-2 flex flex-col items-center md:block"
}
const _hoisted_6 = {
  id: "contacts",
  class: "space-y-1 flex flex-col items-center md:block opacity-50"
}
const _hoisted_7 = {
  id: "socialNetworks",
  class: "space-y-2 flex flex-col items-center md:block"
}
const _hoisted_8 = {
  id: "socialNetworksList",
  class: "flex gap-2 items-center"
}
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavBar, {
      options: _ctx.menuOptions,
      socialNetworks: _ctx.socialNetworks
    }, null, 8, ["options", "socialNetworks"]),
    _createVNode(_component_router_view, {
      class: "mt-16",
      socialNetworks: _ctx.socialNetworks
    }, null, 8, ["socialNetworks"]),
    _createElementVNode("footer", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("h5", { class: "text-xs" }, "Menu", -1)),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuOptions, (option, index) => {
              return (_openBlock(), _createBlock(_component_RouterLink, {
                to: option.link,
                key: index,
                class: "block"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(option.name), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[1] || (_cache[1] = _createElementVNode("h5", { class: "text-xs" }, "Información de contacto", -1)),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactInfo, (contact, index) => {
              return (_openBlock(), _createElementBlock("div", {
                id: "contact",
                class: "flex gap-2",
                key: index
              }, [
                _createVNode(_component_Icon, {
                  iconImg: contact.icon,
                  class: "w-4 bg-white"
                }, null, 8, ["iconImg"]),
                _createElementVNode("p", null, _toDisplayString(contact.info), 1)
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[2] || (_cache[2] = _createElementVNode("h5", { class: "text-xs" }, "Redes sociales", -1)),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialNetworks, (socialNetwork, index) => {
              return (_openBlock(), _createElementBlock("a", {
                href: socialNetwork.link,
                key: index
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["icon p-1.5 rounded-lg overflow-hidden border border-white border-opacity-50 opacity-70 hover:opacity-100", socialNetwork.color])
                }, [
                  _createVNode(_component_Icon, {
                    iconImg: socialNetwork.icon,
                    class: "w-6 bg-white"
                  }, null, 8, ["iconImg"])
                ], 2)
              ], 8, _hoisted_9))
            }), 128))
          ])
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "developer flex gap-8 text-center text-white" }, [
          _createElementVNode("div", { class: "flex flex-col gap-2 items-center mx-auto" }, [
            _createElementVNode("span", { class: "text-xs" }, "Desarrollado por"),
            _createElementVNode("a", {
              href: "https://adro.studio",
              class: "text-xl opacity-50"
            }, "adro.studio")
          ])
        ], -1))
      ])
    ])
  ], 64))
}