import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "navBar",
  class: "h-16 w-full fixed top-0 left-0 bg-zinc-950 py-3 z-50 border-b border-stone-900 select-none"
}
const _hoisted_2 = { class: "container h-full w-full flex justify-between items-center" }
const _hoisted_3 = {
  key: 0,
  id: "menu",
  class: "hidden md:flex items-center gap-8"
}
const _hoisted_4 = {
  id: "mobileNav",
  class: "w-full container flex-1 flex flex-col justify-center"
}
const _hoisted_5 = { class: "space-y-6" }
const _hoisted_6 = {
  id: "navbarFooter",
  class: "flex flex-col space-y-6 items-center p-6"
}
const _hoisted_7 = {
  id: "socialNetworks",
  class: "space-y-2 flex flex-col items-center md:block"
}
const _hoisted_8 = {
  id: "socialNetworksList",
  class: "flex gap-2 items-center"
}
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        src: "/assets/img/animadix.png",
        alt: "Logo animadix",
        class: "h-full"
      }, null, -1)),
      (_ctx.options && _ctx.options.length > 0)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
              return (_openBlock(), _createElementBlock("li", {
                key: option.link
              }, [
                _createVNode(_component_RouterLink, {
                  to: option.link,
                  class: "text-white hover:text-sky-300"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(option.name), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "mobileMenu w-7 rounded-lg md:hidden",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.displayMobileMenu = !_ctx.displayMobileMenu))
      }, [
        _createVNode(_component_Icon, {
          iconImg: _ctx.displayMobileMenu ? '/assets/icons/close.svg' : '/assets/icons/menu.svg',
          class: "w-full bg-white"
        }, null, 8, ["iconImg"])
      ])
    ]),
    _createElementVNode("div", {
      id: "mobileMenu",
      class: _normalizeClass(["fixed top-16 bottom-0 w-full min-h-[calc(100dvh_-_64px)] bg-zinc-950 text-white transition-all duration-500 ease-in-out flex flex-col justify-between", _ctx.displayMobileMenu ? 'right-0' : '-right-full'])
    }, [
      _createElementVNode("nav", _hoisted_4, [
        _createElementVNode("ul", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createVNode(_component_router_link, {
                class: "h-full opacity-50 hover:opacity-100 py-2 text-center",
                to: option.link,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.displayMobileMenu = false))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(option.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[3] || (_cache[3] = _createElementVNode("h5", { class: "text-xs" }, "Redes sociales", -1)),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialNetworks, (socialNetwork, index) => {
              return (_openBlock(), _createElementBlock("a", {
                href: socialNetwork.link,
                key: index
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["icon p-1.5 rounded-lg overflow-hidden border border-white border-opacity-50 opacity-70 hover:opacity-100", socialNetwork.color])
                }, [
                  _createVNode(_component_Icon, {
                    iconImg: socialNetwork.icon,
                    class: "w-6 bg-white"
                  }, null, 8, ["iconImg"])
                ], 2)
              ], 8, _hoisted_9))
            }), 128))
          ])
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "developer text-center text-white" }, [
          _createElementVNode("div", { class: "flex flex-col gap-2 items-center" }, [
            _createElementVNode("span", { class: "text-xs" }, "Desarrollado por"),
            _createElementVNode("a", {
              href: "https://adro.studio",
              class: "text-xl opacity-50"
            }, "adro.studio")
          ])
        ], -1))
      ])
    ], 2)
  ]))
}