<template>
    <div id="card" class="w-full rounded-lg overflow-hidden px-2 py-12 space-y-12 bg-zinc-900">
        <!-- Icon -->
        <div id="icon" class="w-full flex justify-center">
            <Icon :iconImg="icon" class="w-1/3 md:w-1/4 bg-sky-600"></Icon>
        </div>

        <!-- Info -->
        <div id="title" class="w-full p-4 space-y-2 text-white text-center">
            <!-- Title -->
            <h4 class="font-semibold text-xl">{{ title }}</h4>
            <!-- Description -->
            <p class="text-zinc-300 line-clamp-4 h-24">{{ description }}</p>
        </div>
    </div>
</template>

<script lang="ts">
// Custom components
import Icon from './Icon.vue';

// This component
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CardComponent',
    components: {
        Icon
    },
    props: {
        icon: String,
        title: String,
        description: String
    }
})
</script>