import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "card",
  class: "w-full rounded-lg overflow-hidden px-2 py-12 space-y-12 bg-zinc-900"
}
const _hoisted_2 = {
  id: "icon",
  class: "w-full flex justify-center"
}
const _hoisted_3 = {
  id: "title",
  class: "w-full p-4 space-y-2 text-white text-center"
}
const _hoisted_4 = { class: "font-semibold text-xl" }
const _hoisted_5 = { class: "text-zinc-300 line-clamp-4 h-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, {
        iconImg: _ctx.icon,
        class: "w-1/3 md:w-1/4 bg-sky-600"
      }, null, 8, ["iconImg"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.description), 1)
    ])
  ]))
}