<template>
  <div id="homePage">
    <!-- HOME SECTION -->
    <section id="inicio" class="w-full h-[calc(100dvh_-_64px)] flex flex-col">

      <!-- Banner -->
      <div id="banner" class="flex-1 w-full md:h-full md:flex md:flex-col md:justify-around relative overflow-hidden">
        <!-- Background Video -->
        <video src="/assets/video/main_video.mp4" alt="video principal"
          class="h-full w-full object-cover object-right-top" nocontrols autoplay playsinline muted loop preload="auto"></video>
        <!-- Overlay -->
        <div id="bannerOverlay" class="w-full h-full absolute left-0 bottom-0 bg-gradient-to-t from-zinc-950">
        </div>
        <!-- Banner Container -->
        <div id="bannerContainer" class="w-full h-full absolute top-0 left-0">
          <div id="bannerContent" class="container w-full h-full">
            <!-- Banner Content -->
            <div id="bannerContent"
              class="w-full md:w-1/2 h-full space-y-8 text-white text-center md:text-left flex flex-col justify-end md:justify-center">
              <h1 class="text-3xl md:text-5xl font-bold">Profesionales en soluciones para cines</h1>
              <div id="description" class="space-y-4">
                <p class="text-lg md:text-xl">Somos es una empresa compuesta de profesionales certificados, con años de
                  experiencia en instalaciones, puesta en marcha y atención al cliente.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- logos -->
      <div id="logos" class=" flex-0 container w-full overflow-hidden py-8">
        <!-- Logos swiper-->
        <div class="swiper-client swiper-container-free-mode w-full overflow-hidden relative">
          <div class="swiper-wrapper">
            <div class="swiper-slide p-8 bg-zinc-900 rounded-lg" v-for="logo, i in logos" :key="i">
              <div class="w-full aspect-square">
                <img class="w-full h-full object-contain object-center" :src="logo.img" :alt="logo.name">
              </div>
            </div>
          </div>
          <div class="w-[3rem] md:w-[9rem] h-full bg-gradient-to-r from-zinc-950 absolute left-0 top-0 z-10"></div>
          <div class="w-[3rem] md:w-[9rem] h-full bg-gradient-to-l from-zinc-950 absolute right-0 top-0 z-10"></div>
        </div>
      </div>
    </section>

    <!-- About us section -->
    <section id="sobre-nosotros" class="container min-h-[calc(100vh_-_64px)] space-y-8 py-8">
      <div class="bg-zinc-900 rounded-lg space-y-16 overflow-hidden" v-for="elem, index in aboutUs" :key="index" data-aos="fade-up" data-aos-offset="100" data-aos-duration="800">
        <div class="flex flex-col md:gap-16" :class="index % 2 == 0 ? 'md:flex-row' : 'md:flex-row-reverse'">
          <!-- About us -->
          <div
            class="w-full md:w-5/12 flex-none flex flex-col justify-center items-center space-y-8 p-8 md:py-16" :class="index % 2 == 0 ? 'md:pl-16' : 'md:pr-16'">
            <!-- Title -->
            <h2 class="text-3xl md:text-4xl font-bold text-center text-white">{{ elem.title }}</h2>
            <!-- About us text -->
            <div id="descriptionAboutUs" class="text-white w-full text-center space-y-4">
              <p>{{ elem.text }}</p>
            </div>
          </div>
          <!-- About us image -->
          <div class="w-full aspect-[4/3] md:aspect-video relative">
            <img :src="elem.image" class="w-full h-full object-cover object-center" alt="">
            <div class="overlay bg-gradient-to-b from-zinc-900 to-transparent w-full md:w-96 h-1/2 md:h-full absolute top-0"
              :class="index % 2 == 0 ? 'md:bg-gradient-to-r left-0' : 'md:bg-gradient-to-l right-0'"></div>
          </div>
        </div>
      </div>
    </section>

    <!-- Services section -->
    <section id="servicios" class="py-16 md:min-h-[calc(100vh_-_64px)]" data-aos="fade-up" data-aos-offset="100" data-aos-duration="800">
      <div class="container flex flex-col items-center justify-around space-y-8 relative">
        <!-- Title -->
        <h2 class="text-3xl md:text-4xl font-bold text-center text-white">Nuestros servicios</h2>
        <!-- Serv swiper-->
        <div class="swiper-service w-full overflow-hidden relative">
          <div class="swiper-wrapper flex">
            <!-- Service card -->
            <Card :icon="service.icon" :title="service.title" :description="service.description"
              v-for="service, index in services" :key="index" class="swiper-slide flex-grow"></Card>
          </div>
        </div>
        <div id="swiperNavigation" class="w-full flex justify-between">
          <!-- navigation prev -->
          <div class="swiper-button-prev relative !hidden md:!flex"></div>
          <!-- pagination-->
          <div class="swiper-pagination relative"></div>
          <!-- navigation next -->
          <div class="swiper-button-next relative !hidden md:!flex"></div>
        </div>
      </div>
    </section>

    <!-- Contact and about us -->
    <section id="contactanos" class="h-auto md:min-h-[calc(100dvh_-_64px)] container" data-aos="fade-up" data-aos-offset="100" data-aos-duration="800">
      <!-- Contact -->
      <div
        class="w-full md:mx-auto h-auto md:min-h-[calc(100dvh_-_64px)] flex flex-col items-center justify-center pt-14 pb-7 md:pb-14 bg-zinc-800 md:bg-zinc-900 rounded-lg">
        <div class="w-full md:w-1/2 space-y-8 px-4 md:p-0">
          <!-- Title -->
          <h2 class="text-3xl md:text-4xl font-bold text-center text-white mb-2">Contacta con nosotros</h2>
          <!-- Contact form -->
          <div class="w-full space-y-4 text-sm text-white bg-zinc-800 rounded-lg md:p-8">
            <!-- Name -->
            <div id="formField" class="space-y-2">
              <p>Nombre completo</p>
              <input type="text" class="w-full py-2 px-3 rounded-lg bg-zinc-900 border border-zinc-700"
                placeholder="Ingrese su nombre completo">
            </div>
            <!-- Email -->
            <div id="formField" class="space-y-2">
              <p>Email</p>
              <input type="text" class="w-full py-2 px-3 rounded-lg bg-zinc-900 border border-zinc-700"
                placeholder="Ingrese su email">
            </div>
            <!-- Phone -->
            <div id="formField" class="space-y-2">
              <p>Número de teléfono</p>
              <input type="text" class="w-full py-2 px-3 rounded-lg bg-zinc-900 border border-zinc-700"
                placeholder="Ingrese su número de teléfono">
            </div>
            <!-- Message -->
            <div id="formField" class="space-y-2">
              <p>Mensaje</p>
              <textarea type="text" class="w-full py-2 px-3 rounded-lg bg-zinc-900 border border-zinc-700 min-h-32"
                placeholder="Deja el mensaje, duda o consulta que tengas"></textarea>
            </div>
            <!-- Send message button -->
            <div id="buttonContainer" class="w-full flex justify-center">
              <button class="px-4 py-2 bg-sky-600 hover:bg-sky-500 rounded-lg overflow-hidden">
                <p>Enviar mensaje</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
//AOS
import 'aos/dist/aos.css'
import AOS from 'aos'

// import Swiper JS
import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// Custom components
import Card from '@/components/Card.vue';

// This view
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    Card
  },
  props: {
    socialNetworks: {
      type: Array as PropType<{ icon: string; link: string, color: string }[]>,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      logos: [
        {
          name: 'Phillips',
          img: '/assets/logos/phillips.png'
        },
        {
          name: 'Dolby',
          img: '/assets/logos/dolby.png'
        },
        {
          name: 'NEC',
          img: '/assets/logos/nec.png'
        },
        {
          name: 'Ushio',
          img: '/assets/logos/ushio.png'
        },
        {
          name: 'Cielo',
          img: '/assets/logos/cielo.png'
        },
        {
          name: 'Doremi',
          img: '/assets/logos/doremi.png'
        },
        {
          name: 'QSC',
          img: '/assets/logos/qsc.png'
        }
      ],
      aboutUs: [
        {
          title: 'Brindamos soluciones integrales para el Cine Digital',
          text: 'Somos un equipo de profesionales certificados con amplia experiencia en instalaciones, puesta en marcha y atención al cliente. Nuestro compromiso es ofrecer servicios de primer nivel, diseñados para satisfacer cada necesidad de los exhibidores.',
          image: '/assets/img/cinema_projector.webp'
        },
        {
          title: 'Estamos para patenciar tu sala de cine al máximo',
          text: 'Nuestra misión es potenciar la experiencia cinematográfica con soluciones innovadoras y de alta calidad. Si buscas un aliado estratégico en el mundo del Cine Digital, estamos aquí para ayudarte.',
          image: '/assets/img/cinema_theater.webp'
        },
        {
          title: 'Equipamos tu evento cultural o corporativo',
          text: 'Ofrecemos soluciones visuales de alta calidad para tu evento. Nos encargamos de proporcionar el equipamiento necesario para una experiencia impecable y profesional.',
          image: '/assets/img/cinema_event.png'
        }
      ],
      services: [
        {
          title: 'Servicio Técnico',
          icon: '/assets/icons/services/technicalService.svg',
          description: 'Asistencia técnica especializada tanto en sitio como en nuestro laboratorio, garantizando soluciones rápidas y eficientes para tu equipo de cine.'
        },
        {
          title: 'Mantenimiento',
          icon: '/assets/icons/services/maintenance.svg',
          description: 'Planes de mantenimiento personalizados que se adaptan a las necesidades específicas de cada sala de cine, asegurando un rendimiento óptimo y continuo.'
        },
        {
          title: 'Consultoría',
          icon: '/assets/icons/services/consultancy.svg',
          description: 'Asesoramiento en dimensionamiento de salas y evaluación exhaustiva del estado del equipamiento, optimizando tu inversión y rendimiento.'
        },
        {
          title: 'Ventas',
          icon: '/assets/icons/services/sales.svg',
          description: 'Suministro de repuestos originales, equipos de última generación e insumos esenciales para mantener tu cine a la vanguardia tecnológica.'
        },
        {
          title: 'Exhibición',
          icon: '/assets/icons/services/exhibition.svg',
          description: 'Servicios integrales de montaje y exhibición de cines, desde la planificación hasta la puesta en marcha, creando experiencias cinematográficas inolvidables.'
        },
        {
          title: 'Calibración',
          icon: '/assets/icons/services/calibration.svg',
          description: 'Ajuste preciso de imagen y sonido para garantizar una calidad audiovisual excepcional, mejorando la experiencia del espectador.'
        },
        {
          title: 'Alquiler',
          icon: '/assets/icons/services/rent.svg',
          description: 'Opciones flexibles de alquiler de equipamiento cinematográfico de alta calidad, adaptándonos a tus necesidades temporales o proyectos especiales.'
        },
        {
          title: 'Capacitaciones',
          icon: '/assets/icons/services/training.svg',
          description: 'Programas de formación especializados para técnicos y proyeccionistas, actualizando sus conocimientos y habilidades en las últimas tecnologías de cine digital.'
        },
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.createSwipers()
      AOS.init()
    })
  },
  methods: {
    createSwipers() {
      this.createClientsSwiper()
      this.createServiceSwiper()
    },
    createClientsSwiper() {
      //create swiper
      const swiperOpts = {
        slidesPerView: 3,
        spaceBetween: 24,
        speed: 5000,
        loop: true,
        loopAddBlankSlides: true,
        freeMode: true,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        breakpoints: {
          768: {
            slidesPerView: 6,
          }
        },
        modules: [Autoplay]
      }

      new Swiper('.swiper-client', swiperOpts)
    },
    createServiceSwiper() {
      //create swiper
      const swiperOpts = {
        slidesPerView: 1.5,
        centeredSlides: true,
        spaceBetween: 24,
        loop: true,
        breakpoints: {
          768: {
            slidesPerView: 4,
          }
        },
        speed: 800,
        autoplay: {
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination'
        },
        modules: [Autoplay, Navigation, Pagination]
      }

      new Swiper('.swiper-service', swiperOpts)
    }
  }
});
</script>

<style>
.swiper-container-free-mode>.swiper-wrapper {
  transition-timing-function: linear;
}

.swiper-pagination-bullet {
  background-color: white
}

.swiper-pagination-bullet-active {
  background-color: #0369a1
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: #0369a1;
  font-size: 20px;
}
</style>