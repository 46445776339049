<template>
    <div id="navBar" class="h-16 w-full fixed top-0 left-0 bg-zinc-950 py-3 z-50 border-b border-stone-900 select-none">
        <div class="container h-full w-full flex justify-between items-center">
            <img src="/assets/img/animadix.png" alt="Logo animadix" class="h-full" />
            <!-- Desktop menu -->
            <ul id="menu" v-if="options && options.length > 0" class="hidden md:flex items-center gap-8">
                <li v-for="option in options" :key="option.link">
                    <RouterLink :to="option.link" class="text-white hover:text-sky-300">
                        <p>{{ option.name }}</p>
                    </RouterLink>
                </li>
            </ul>
            <!-- Mobile menu icon -->
            <div class="mobileMenu w-7 rounded-lg md:hidden" @click="displayMobileMenu = !displayMobileMenu">
                <Icon :iconImg="displayMobileMenu ? '/assets/icons/close.svg' : '/assets/icons/menu.svg'"
                    class="w-full bg-white"></Icon>
            </div>
        </div>
        <!--Mobile menu-->
        <div id="mobileMenu"
            class="fixed top-16 bottom-0 w-full min-h-[calc(100dvh_-_64px)] bg-zinc-950 text-white transition-all duration-500 ease-in-out flex flex-col justify-between"
            :class="displayMobileMenu ? 'right-0' : '-right-full'">
            <!-- Menu -->
            <nav id="mobileNav" class="w-full container flex-1 flex flex-col justify-center">
                <ul class="space-y-6">
                    <li v-for="option, index in options" :key="index">
                        <router-link class="h-full opacity-50 hover:opacity-100 py-2 text-center" :to="option.link"
                            @click="displayMobileMenu = false">
                            <p>{{ option.name }}</p>
                        </router-link>
                    </li>
                </ul>
            </nav>
            <!-- NavBar Footer Mobile -->
            <div id="navbarFooter" class="flex flex-col space-y-6 items-center p-6">
                <!-- Social networks -->
                <div id="socialNetworks" class="space-y-2 flex flex-col items-center md:block">
                    <h5 class="text-xs">Redes sociales</h5>
                    <div id="socialNetworksList" class="flex gap-2 items-center">
                        <a :href="socialNetwork.link" v-for="socialNetwork, index in socialNetworks" :key="index">
                            <div class="icon p-1.5 rounded-lg overflow-hidden border border-white border-opacity-50 opacity-70 hover:opacity-100"
                                :class="socialNetwork.color">
                                <Icon :iconImg="socialNetwork.icon" class="w-6 bg-white"></Icon>
                            </div>
                        </a>
                    </div>
                </div>

                <!-- Developer -->
                <div class="developer text-center text-white">
                    <div class="flex flex-col gap-2 items-center">
                        <span class="text-xs">Desarrollado por</span>
                        <a href="https://adro.studio" class="text-xl opacity-50">adro.studio</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
//Custom components
import Icon from './Icon.vue';

//This component
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'NavBar',
    components: {
        Icon
    },
    props: {
        options: {
            type: Array as PropType<{ name: string; link: string }[]>,
            required: true,
            default: () => []
        },
        socialNetworks: {
            type: Array as PropType<{ link: string; color: string; icon: string }[]>,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            displayMobileMenu: false
        }
    }
})
</script>