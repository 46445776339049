import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { id: "homePage" }
const _hoisted_2 = {
  id: "inicio",
  class: "w-full h-[calc(100dvh_-_64px)] flex flex-col"
}
const _hoisted_3 = {
  id: "logos",
  class: "flex-0 container w-full overflow-hidden py-8"
}
const _hoisted_4 = { class: "swiper-client swiper-container-free-mode w-full overflow-hidden relative" }
const _hoisted_5 = { class: "swiper-wrapper" }
const _hoisted_6 = { class: "w-full aspect-square" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  id: "sobre-nosotros",
  class: "container min-h-[calc(100vh_-_64px)] space-y-8 py-8"
}
const _hoisted_9 = { class: "text-3xl md:text-4xl font-bold text-center text-white" }
const _hoisted_10 = {
  id: "descriptionAboutUs",
  class: "text-white w-full text-center space-y-4"
}
const _hoisted_11 = { class: "w-full aspect-[4/3] md:aspect-video relative" }
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  id: "servicios",
  class: "py-16 md:min-h-[calc(100vh_-_64px)]",
  "data-aos": "fade-up",
  "data-aos-offset": "100",
  "data-aos-duration": "800"
}
const _hoisted_14 = { class: "container flex flex-col items-center justify-around space-y-8 relative" }
const _hoisted_15 = { class: "swiper-service w-full overflow-hidden relative" }
const _hoisted_16 = { class: "swiper-wrapper flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", {
        id: "banner",
        class: "flex-1 w-full md:h-full md:flex md:flex-col md:justify-around relative overflow-hidden"
      }, [
        _createElementVNode("video", {
          src: "/assets/video/main_video.mp4",
          alt: "video principal",
          class: "h-full w-full object-cover object-right-top",
          nocontrols: "",
          autoplay: "",
          playsinline: "",
          muted: "",
          loop: "",
          preload: "auto"
        }),
        _createElementVNode("div", {
          id: "bannerOverlay",
          class: "w-full h-full absolute left-0 bottom-0 bg-gradient-to-t from-zinc-950"
        }),
        _createElementVNode("div", {
          id: "bannerContainer",
          class: "w-full h-full absolute top-0 left-0"
        }, [
          _createElementVNode("div", {
            id: "bannerContent",
            class: "container w-full h-full"
          }, [
            _createElementVNode("div", {
              id: "bannerContent",
              class: "w-full md:w-1/2 h-full space-y-8 text-white text-center md:text-left flex flex-col justify-end md:justify-center"
            }, [
              _createElementVNode("h1", { class: "text-3xl md:text-5xl font-bold" }, "Profesionales en soluciones para cines"),
              _createElementVNode("div", {
                id: "description",
                class: "space-y-4"
              }, [
                _createElementVNode("p", { class: "text-lg md:text-xl" }, "Somos es una empresa compuesta de profesionales certificados, con años de experiencia en instalaciones, puesta en marcha y atención al cliente.")
              ])
            ])
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logos, (logo, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "swiper-slide p-8 bg-zinc-900 rounded-lg",
                key: i
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("img", {
                    class: "w-full h-full object-contain object-center",
                    src: logo.img,
                    alt: logo.name
                  }, null, 8, _hoisted_7)
                ])
              ]))
            }), 128))
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "w-[3rem] md:w-[9rem] h-full bg-gradient-to-r from-zinc-950 absolute left-0 top-0 z-10" }, null, -1)),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-[3rem] md:w-[9rem] h-full bg-gradient-to-l from-zinc-950 absolute right-0 top-0 z-10" }, null, -1))
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aboutUs, (elem, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "bg-zinc-900 rounded-lg space-y-16 overflow-hidden",
          key: index,
          "data-aos": "fade-up",
          "data-aos-offset": "100",
          "data-aos-duration": "800"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex flex-col md:gap-16", index % 2 == 0 ? 'md:flex-row' : 'md:flex-row-reverse'])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["w-full md:w-5/12 flex-none flex flex-col justify-center items-center space-y-8 p-8 md:py-16", index % 2 == 0 ? 'md:pl-16' : 'md:pr-16'])
            }, [
              _createElementVNode("h2", _hoisted_9, _toDisplayString(elem.title), 1),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("p", null, _toDisplayString(elem.text), 1)
              ])
            ], 2),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("img", {
                src: elem.image,
                class: "w-full h-full object-cover object-center",
                alt: ""
              }, null, 8, _hoisted_12),
              _createElementVNode("div", {
                class: _normalizeClass(["overlay bg-gradient-to-b from-zinc-900 to-transparent w-full md:w-96 h-1/2 md:h-full absolute top-0", index % 2 == 0 ? 'md:bg-gradient-to-r left-0' : 'md:bg-gradient-to-l right-0'])
              }, null, 2)
            ])
          ], 2)
        ]))
      }), 128))
    ]),
    _createElementVNode("section", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "text-3xl md:text-4xl font-bold text-center text-white" }, "Nuestros servicios", -1)),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (service, index) => {
              return (_openBlock(), _createBlock(_component_Card, {
                icon: service.icon,
                title: service.title,
                description: service.description,
                key: index,
                class: "swiper-slide flex-grow"
              }, null, 8, ["icon", "title", "description"]))
            }), 128))
          ])
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", {
          id: "swiperNavigation",
          class: "w-full flex justify-between"
        }, [
          _createElementVNode("div", { class: "swiper-button-prev relative !hidden md:!flex" }),
          _createElementVNode("div", { class: "swiper-pagination relative" }),
          _createElementVNode("div", { class: "swiper-button-next relative !hidden md:!flex" })
        ], -1))
      ])
    ]),
    _cache[5] || (_cache[5] = _createStaticVNode("<section id=\"contactanos\" class=\"h-auto md:min-h-[calc(100dvh_-_64px)] container\" data-aos=\"fade-up\" data-aos-offset=\"100\" data-aos-duration=\"800\"><div class=\"w-full md:mx-auto h-auto md:min-h-[calc(100dvh_-_64px)] flex flex-col items-center justify-center pt-14 pb-7 md:pb-14 bg-zinc-800 md:bg-zinc-900 rounded-lg\"><div class=\"w-full md:w-1/2 space-y-8 px-4 md:p-0\"><h2 class=\"text-3xl md:text-4xl font-bold text-center text-white mb-2\">Contacta con nosotros</h2><div class=\"w-full space-y-4 text-sm text-white bg-zinc-800 rounded-lg md:p-8\"><div id=\"formField\" class=\"space-y-2\"><p>Nombre completo</p><input type=\"text\" class=\"w-full py-2 px-3 rounded-lg bg-zinc-900 border border-zinc-700\" placeholder=\"Ingrese su nombre completo\"></div><div id=\"formField\" class=\"space-y-2\"><p>Email</p><input type=\"text\" class=\"w-full py-2 px-3 rounded-lg bg-zinc-900 border border-zinc-700\" placeholder=\"Ingrese su email\"></div><div id=\"formField\" class=\"space-y-2\"><p>Número de teléfono</p><input type=\"text\" class=\"w-full py-2 px-3 rounded-lg bg-zinc-900 border border-zinc-700\" placeholder=\"Ingrese su número de teléfono\"></div><div id=\"formField\" class=\"space-y-2\"><p>Mensaje</p><textarea type=\"text\" class=\"w-full py-2 px-3 rounded-lg bg-zinc-900 border border-zinc-700 min-h-32\" placeholder=\"Deja el mensaje, duda o consulta que tengas\"></textarea></div><div id=\"buttonContainer\" class=\"w-full flex justify-center\"><button class=\"px-4 py-2 bg-sky-600 hover:bg-sky-500 rounded-lg overflow-hidden\"><p>Enviar mensaje</p></button></div></div></div></div></section>", 1))
  ]))
}